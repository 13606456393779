(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProgramProviderListController', ProgramProviderListController);

    /** @ngInject **/
    function ProgramProviderListController($translate, $state, $rootScope, $stateParams, $window, pagingParams, paginationConstants, SessionPromise, ProgramProvider, ProgramProviderCategory, CompanyProvider, Company, StringUtils, ParseLinks, noty) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.currentSearch = pagingParams.query;
        vm.providers = [];
        vm.categories = [];
        vm.programProviders = [];
        vm.companies = [];

        loadAll();

        function loadAll() {
            ProgramProvider.getByProgram(
                {
                    id: $stateParams.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    q: vm.currentSearch
                }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                //vm.totalItems = headers('X-Total-Count');
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.page = data.number + 1;

                data.content.forEach(function ($provider) {
                    if ($provider.category == null) {
                        $provider.category = undefined;
                    }
                });

                vm.providers = data.content;

                if (vm.providers) {
                    angular.forEach(vm.providers, function ($fin) {
                        if ($fin.company && $fin.company) {
                            vm.companies.push(StringUtils.formatToNameAndIdentificationLabel($fin.company));
                        }
                    });
                }

                vm.providers.forEach(function (item) {
                    item.isDisabled = true;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: $stateParams.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                query: vm.currentSearch
            });
        }

        vm.search = function () {
            transition();
        };

        vm.createCategory = function (name, callback, provider) {
            provider.category = {
                name: name,
                rate: provider.rate
            };
            vm.categories.push(provider.category);
            callback(provider.category);
        };

        vm.categorySelected = function (id, $model, provider) {
            provider.category = $model;
            if (provider.category && provider.category.id < 0) {
                provider.category.id = undefined;
            }
        };

        ProgramProviderCategory.query(function (result) {
            vm.categories = vm.categories.concat(result);
        });

        vm.loadCategories = function (name, callback) {
            ProgramProviderCategory.query({q: name, limit: 50}, function (result) {
                vm.categories = vm.categories.concat(result);
                callback(vm.categories);
            }, function () {
                callback(vm.categories);
            });
        };

        Company.getCompaniesForProgram({programId: $stateParams.id}, function (result) {
            vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result.content));
            $rootScope.$broadcast('update.company');
        });

        vm.providerSelected = function (id, $model, provider) {
            provider.company = $model;
        };

        vm.loadCompanies = function (searchText, callback) {
            Company.getCompaniesForProgram({q: searchText, programId: $stateParams.id, limit: 50}, function (result) {
                vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result.content));
                $rootScope.$broadcast('update.company');
                callback(vm.companies);
            }, function () {
                callback(vm.companies);
            });
        };

        vm.edit = function (item) {
            item.isDisabled = false;
        };

        vm.save = function (item) {
            item.isDisabled = true;

            item.program = {
                id: $stateParams.id
            };

            if (item.category && item.category.id < 0) {
                item.category.id = undefined;
            }

            if (item.id) {
                ProgramProvider.updateProgramProviderForProgram(item, onSuccess, onError);
            } else {
                ProgramProvider.createProgramProviderForProgram(item, onSuccess, onError);
            }

            function onSuccess() {
            }

            function onError() {
                item.isDisabled = false;
            }
        };

        vm.addProvider = function () {
            vm.providers.push({});
        };

        vm.removeProvider = function (index) {
            var item = vm.providers[index];
            if (item.id) {
                ProgramProvider.remove({id: item.id}, function () {
                    vm.providers.splice(index, 1);
                });
            } else {
                vm.providers.splice(index, 1);
            }
        };

        vm.activeProvider = function (program) {
            ProgramProvider.active({id: program.id})
        };

        vm.disableProvider = function (program) {
            ProgramProvider.disable({id: program.id})
        };

        vm.sessionPromise = function (provider) {
            provider.generatingSessionPromise = true;

            SessionPromise.generate(
                {id: provider.id},
                function (result) {
                    provider.generatingSessionPromise = false;
                    noty.success($translate.instant('message.alert.session.promise.sent.signature'));
                },
                function (result) {
                    provider.generatingSessionPromise = false;
                })
        };

        vm.downloadSessionPromise = function (provider) {
            SessionPromise.download({id: provider.id}, function (result) {
                var fileName = "promessa-cessao-credito-" + provider.company.identification + ".pdf";
                debugBase64(result, fileName);
            })
        };

        function debugBase64(response, fileName) {
            var byteCharacters = atob(response.file);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        vm.clear = function () {
            $state.go("program");
        }
    }
})();
